<template>
  <b-form @submit.prevent>
    <h3>Контактные данные</h3>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="ФИО:"
          label-for="name"
        >
          <validation-provider
            #default="{ errors }"
            name="name"
            vid="name"
          >
            <b-form-input
              id="name"
              v-model="usersData.name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="E-mail:"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            name="email"
            vid="email"
          >
            <b-form-input
              id="email"
              v-model="usersData.email"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12">
        <h3>Безопасность</h3>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Новый пароль:"
          label-for="password"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            vid="password"
          >
            <b-input-group>
              <b-form-input
                id="password"
                v-model="usersData.password"
                placeholder="Например: inio4smfvd0p2rm"
              />
              <b-input-group-append>
                <b-button
                  v-b-tooltip.hover
                  variant="outline-primary"
                  title="Сгенерировать случайный пароль"
                  @click="genPassword"
                >
                  <feather-icon icon="RefreshCcwIcon" />
                </b-button>
                <b-button
                  v-clipboard:copy="usersData.password"
                  v-clipboard:success="successCopy"
                  v-b-tooltip.hover
                  variant="outline-primary"
                  title="Скопировать пароль в буфер обмена"
                >
                  <feather-icon icon="CopyIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <small
            id="passwordHelp"
            class="form-text text-muted"
          >Оставьте поле пустым, если не хотите менять пароль.</small>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-button
          variant="primary"
          class="float-right"
          @click="formSubmit"
        >
          {{ $route.params.id ? 'Обновить и закрыть' : 'Создать и закрыть' }}
        </b-button>
      </div>
      <div>
        <b-button
          variant="flat-danger"
          class="mr-1"
          @click="$router.back()"
        >
          Отменить
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  VBTooltip,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'UsersForm',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    usersData: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      allPosition: null,
      avatar: null,
    }
  },
  computed: {
    photoPreview() {
      return this.avatar ?? (typeof this.usersData.photo === 'string' ? (this.$imageBaseUrl + this.usersData.photo) : null)
    },
  },
  methods: {
    openFileDialog(ref) {
      this.$refs[ref].$el.getElementsByTagName('input')[0].click()
    },
    fileChanged(e) {
      const selectedFile = e.target.files[0]
      this.avatar = URL.createObjectURL(selectedFile)
      this.usersData.photo = selectedFile
    },
    formSubmit() {
      this.$emit('formSubmit', true)
    },
    genPassword() {
      const pass = (Math.random()
        .toString(36)
        .slice(-8)) + (Math.random()
        .toString(36)
        .slice(-7))
      this.usersData.password = pass
    },
    successCopy() {
      this.sendToast({
        title: '',
        icon: 'CheckIcon',
        text: 'Пароль успешно скопирован.',
        variant: 'success',
      })
    },
  },
}
</script>
