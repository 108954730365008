<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="lg"
      :opacity=".7"
    >
      <ValidationObserver
        v-if="!loading"
        ref="form"
      >
        <Form
          :users-data.sync="formData"
          @formSubmit="doFormSave"
        />
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import _ from 'lodash'
import axiosIns from '@/libs/axios'
import Form from './Form.vue'

const emptyFormData = Object.freeze({
  name: '',
  email: '',
  password: '',
})

export default {
  name: 'UsersUpdate',
  components: {
    BCard,
    Form,
    BOverlay,
  },
  metaInfo: {
    title: 'Редактирование пользователя',
  },
  data() {
    return {
      formData: _.cloneDeep(emptyFormData),
      loading: false,
    }
  },
  mounted() {
    Promise.all([
      this.loadUser(),
    ]).then(() => {
      this.loading = false
    })
  },
  methods: {
    async doFormSave() {
      this.loading = true

      try {
        const res = await axiosIns({
          method: 'POST',
          url: `/users/${this.$route.params.id}`,
          data: this.formData,
        })
        this.loading = false

        if (res.data.status === 'success') {
          this.$router.push({ name: 'users' })
          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Пользователь обновлен!',
            variant: 'success',
          })
        }
      } catch (error) {
        this.loading = false
        if (error.response.status === 422) {
          this.serverErrors = error.response.data.data
          this.$refs.updateForm.setErrors(error.response.data.data)
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Некоторые поля не заполнены, или заполнены неверно!',
            variant: 'danger',
          })
        }
      }
    },
    async loadUser() {
      const res = await axiosIns({
        method: 'GET',
        url: `/users/show/${this.$route.params.id}`,
      })

      this.formData = _.merge(this.formData, res.data.data)

      return res
    },
  },
}
</script>
